const menu = () => {
  const menuBtn = document.querySelector('.main-header__btn');
  const manualAside = document.querySelector('.manual__aside');

  if (menuBtn && manualAside) {
    const overlay = document.querySelector('.manual__overlay');

    const closeElements = function () {
      manualAside.classList.remove('manual__aside--show');
      overlay.classList.remove('manual__overlay--show');
      menuBtn.classList.remove('main-header__btn--close');
    };

    const onMenuBtnClick = function (evt) {
      evt.preventDefault();
      manualAside.classList.toggle('manual__aside--show');
      overlay.classList.toggle('manual__overlay--show');
      menuBtn.classList.toggle('main-header__btn--close');
      const swiperPaginations = document.querySelectorAll('.swiper-pagination-bullet');
      swiperPaginations.forEach(function (pagination) {
        pagination.addEventListener('click', closeElements);
      });
    };

    const onOverlayClick = function (evt) {
      evt.preventDefault();
      closeElements();
    };

    menuBtn.addEventListener('click', onMenuBtnClick);
    overlay.addEventListener('click', onOverlayClick);
  }

};

export { menu };
