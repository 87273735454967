const modalResize = () => {
  // const modal = document.querySelector('.modal');
  // const manual = document.querySelector('.manual');
  const mediaSize = 600;
  const $manual = document.querySelector('.manual');
  const $slides = $manual.querySelectorAll('.manual__item');
  const btnDown = $manual.querySelectorAll('.manual__control-btn--down');
  const btnPrev = $manual.querySelectorAll('.manual__control-btn--prev');
  const btnNext = $manual.querySelectorAll('.manual__control-btn--next');
  const manualList = document.querySelector('.manual__list');
  const note = document.querySelector('.note');


  const initManualSLider = () => {
    // const modal = document.querySelector('.modal');
    // const mediaSize = 736;

    // if ($manual && (window.innerWidth > mediaSize)) {
    // if (window.innerWidth >= mediaSize) {
    // console.warn('работает слайд');

    const projectsSwiper = new Swiper('.manual__container', {
      slidesPerView: 1,
      speed: 800,
      spaceBetween: 30,

      navigation: {
        prevEl: '.manual__control-btn--prev',
        nextEl: '.manual__control-btn--next',
      },

      pagination: {
        el: '.manual__aside',
        clickable: true,
        type: 'bullets',

        renderBullet: function (index, className) {
          const slide = $slides[index].dataset.manual;
          return `<div class=${className}><span>${slide}</span></div>`;
        },
      },

    });

    const realIndex = projectsSwiper.realIndex;
    const slide = projectsSwiper.slides[realIndex];
    // console.log(slide);
    if (slide) {
      manualList.style.height = slide.children[0].clientHeight + 'px';
    }

    const onBtnDownClick = function () {
      window.scrollTo(pageXOffset, 0);
    };

    btnPrev.forEach(function (btn) {
      btn.style.display = 'none';
    });

    btnDown.forEach(function (btn) {
      btn.addEventListener('click', onBtnDownClick);
    });

    const slideHeightChange = function () {
      const realIndex2 = projectsSwiper.realIndex;
      const slide2 = projectsSwiper.slides[realIndex2];
      manualList.style.height = '100%';
      manualList.style.height = slide2.children[0].clientHeight + 'px';

      if (realIndex2 === 0) {
        btnPrev.forEach(function (btn) {
          btn.style.display = 'none';
        });
        note.classList.remove('note--hide');
      } else {
        btnPrev.forEach(function (btn) {
          btn.style.display = 'block';
        });
        note.classList.add('note--hide');
      } if (projectsSwiper.isEnd) {
        btnNext.forEach(function (btn) {
          btn.style.display = 'none';
        });
      } else {
        btnNext.forEach(function (btn) {
          btn.style.display = 'block';
        });
      }
    };

    projectsSwiper.on('slideChange', slideHeightChange);

    const onGuide2Click = function (evt) {
      evt.preventDefault();
      projectsSwiper.slideTo(2);
    };
    const onGuide4Click = function (evt) {
      evt.preventDefault();
      projectsSwiper.slideTo(4);
    };
    const onGuide42Click = function (evt) {
      evt.preventDefault();
      projectsSwiper.slideTo(5);
    };
    const onGuide10Click = function (evt) {
      evt.preventDefault();
      projectsSwiper.slideTo(10);
    };
    const onGuide11Click = function (evt) {
      evt.preventDefault();
      projectsSwiper.slideTo(11);
    };

    document.querySelector('[href="#guide-2"]').addEventListener('click', onGuide2Click);
    document.querySelector('[href="#guide-4"]').addEventListener('click', onGuide4Click);
    document.querySelector('[href="#guide-4-2"]').addEventListener('click', onGuide42Click);
    document.querySelector('[href="#guide-10"]').addEventListener('click', onGuide10Click);
    document.querySelector('[href="#guide-11"]').addEventListener('click', onGuide11Click);
    // }

    // return slideHeightChange;
  };
  // initManualSLider();
  // initManualSLider();
  // const test = function () {
  if (window.innerWidth >= mediaSize) {
    initManualSLider();
  }
  // };

  // const showModalOrientaion = function () {
  //   // if (((window.orientation === 90 || window.orientation === -90) && (document.clientWidth >= mediaSize || document.clientHeight >= mediaSize)) || (window.innerWidth >= mediaSize)) {
  //   // if ((window.orientation === 90 || window.orientation === -90) && (window.screen.height >= mediaSize) || (window.innerWidth >= mediaSize)) {
  //   if (window.innerWidth >= mediaSize) {
  //     // WHEN IN PORTRAIT MODE
  //     // modal.classList.remove('modal--show');
  //     // manual.classList.add('manual--show');
  //     // initManualSLider();
  //     console.warn('работает orientationchange');
  //   } else {
  //     // WHEN IN LANDSCAPE MODE
  //     // modal.classList.add('modal--show');
  //     // manual.classList.remove('manual--show');
  //   }
  //   // };
  // };

  // const showModalOrientaion2 = function () {
  //   if (window.innerWidth >= mediaSize) {
  //     // modal.classList.remove('modal--show');
  //     // manual.classList.add('manual--show');
  //     console.warn('работает resize');
  //     console.warn(manualList);
  //     console.warn(manual);

  //     // if (!manual) {
  //     //   initManualSLider();
  //     // }
  //   } else {
  //     // modal.classList.add('modal--show');
  //     // manual.classList.remove('manual--show');
  //   }
  //   // };
  // };

  const resizeWindow = function () {
    if (!manualList.style.height) {
      initManualSLider();
      // console.log('работает');
    }
  };
  // const showModalOrientaion3 = function () {
  //   showModalOrientaion2();
  //   window.addEventListener('resize', initManualSLider);
  // };
  // window.addEventListener('load', initManualSLider);
  // window.addEventListener('orientationchange', test);
  // window.addEventListener('orientationchange', initManualSLider);
  // window.addEventListener('orientationchange', test);
  window.addEventListener('resize', resizeWindow);
};

// window.addEventListener('orientationchange', initManualSLider);
export { modalResize };
