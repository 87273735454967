import './modules/cardsHover';
// import './modules/scroll';
// import {initManualSLider} from './modules/initManualSLider';
import {modalResize} from './modules/initManualSLider';
// import {modalResize} from './modules/modal-resize';
import {menu} from './modules/menu';

// initManualSLider();
modalResize();
menu();
