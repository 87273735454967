const list4 = document.querySelector('.guide-4__list-equipment');
const showPicture = (item, hover = false) => {
  if (item) {
    const menuItem = item.closest('.guide__item-hover');
    const equipmentDescription = item.closest('.equipment__description');
    if (menuItem) {
      const cardElement = menuItem.closest('.manual__item');
      if (cardElement) {
        const img = cardElement.querySelector(`img[data-item="${menuItem.dataset.item}"]`);
        if (!img) {
          return;
        }
        if (hover) {
          img.classList.add('visible');
          setTimeout(() => {
            img.parentElement.classList.add('guide__img--hover');
          });
        } else {
          img.classList.remove('visible');
          img.parentElement.classList.remove('guide__img--hover');
        }
      }
    }

    if (equipmentDescription && list4) {
      if (hover) {
        setTimeout(() => {
          list4.classList.add('hover');
        });
      } else {
        list4.classList.remove('hover');
      }
    }
  }
};

document.addEventListener('mouseover', (e) => {
  showPicture(e.target, true);
  showPicture(e.relatedTarget);
});
